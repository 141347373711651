<!-- 纯粹的嵌入页音视频预览 -->
<template>
  <div class="video-preview-wapper" v-loading="loading" element-loading-text="请求中">
    <video-preview ref="videoPreview" v-if="videoParam" :videoParam="videoParam"></video-preview>
  </div>
</template>
<script>
  import VideoPreview from './VideoPreview/index.vue';
  import to from 'await-to-js';
  export default {
    components: {
      VideoPreview
    },
    data() {
      return {
        loading: false,
        videoParam: undefined
      };
    },
    created() {
      this.init(this.$route.query.id);
    },
    methods: {
      async init(id) {
        if (!id) {
          this.$vhMessage.error('缺失参数id');
          return;
        }
        this.loading = true;
        const [err, res] = await to(
          this.$fetch('videoInfo', {
            video_id: id
          })
        );
        this.loading = false;
        if (err || !res || res.code != 200) {
          this.$vhMessage.error(res?.msg || err?.msg || '获取信息失败');
          return;
        }
        if (!res.data.msg_url) {
          res.data.msg_url = res.data.name.substring(res.data.name.lastIndexOf('.'));
        }
        if (res.data.transcode_status != 1) {
          this.$vhMessage.warning('只有转码成功才能查看');
          return;
        }
        this.videoParam = res.data;
      }
    }
  };
</script>
<style lang="less">
  .video-preview-wapper {
    width: 100%;
    height: 100%;
  }
</style>
